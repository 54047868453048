import NewsFetchCoordinator from 'OK/NewsFetchCoordinator';
import pts from 'OK/pts!one.app.community.dk.rdk.gui.format';

/**
 * Набор функций для работы со временем (различные действия, форматирование)
 * Выпил DateTimeUtil.java из GWT
 */
export class DateTimeUtils {
    public static MS_IN_DAY = 86_400_000;
    private static timeZoneOffset = -1;

    /**
     * Вычисляет текущее клиентское время в миллисекундах
     */
    public static getCurrentClientTimeMs = (): number => {
        return Date.now();
    }

    /**
     * Вычисляет текущее серверное время в миллисекундах
     */
    public static getCurrentServerTimeMs = (): number => {
        return NewsFetchCoordinator.getCurrentServerTime();
    }

    /**
     * Вычисляет разницу во времени между клиентом и сервером в миллисекундах
     */
    public static getClientServerTimeDifferenceMs = (): number => {
        return NewsFetchCoordinator.getClientServerTimeDiff();
    }

    /**
     * Приводит серверное время к клиентскому в миллисекундах
     */
    public static convertServerToClientTimeMs = (serverTimeMs: number): number => {
        return serverTimeMs + this.getClientServerTimeDifferenceMs();
    }

    /**
     * Округляет дату в миллисекундах до начала дня в миллисекундах c учетом часового пояса
     */
    public static roundClientDateByTimeMs = (dateMs: number): number => {
        const offsetTime = this.getTimezoneOffsetMs();
        const UTCTime = dateMs - offsetTime;
        const UTCTimeWithRoundedDay = UTCTime - (UTCTime % this.MS_IN_DAY);

        return UTCTimeWithRoundedDay + offsetTime;
    }

    /**
     * Вычисляет начало клиентского дня в миллисекундах
     * */
    public static getClientTodayStartMs = (): number => {
        const currentTimeMs = this.getCurrentClientTimeMs();

        return this.roundClientDateByTimeMs(currentTimeMs);
    }

    /**
     * Вычисляет вчерашнее начало клиентского дня в миллисекундах
     * */
    public static getClientYesterdayStartMs = (): number => {
        return this.getClientTodayStartMs() - this.MS_IN_DAY;
    }

    /**
     * Приводит переданное время в миллисекундах к виду HH:mm
     * */
    public static formatDateToHHMM = (dateMs: number): string => {
        const shortTimeFormat = new Intl.DateTimeFormat('default', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        });
        const d = new Date(dateMs)

        return shortTimeFormat.format(d);
    }

    /**
     * Вычисляет смещение временной текущего времени относительно UTC в миллисекундах
     * */
    public static getTimezoneOffsetMs = (): number => {
        if (this.timeZoneOffset == -1) {
            const offsetInMinutes = new Date().getTimezoneOffset();
            this.timeZoneOffset = offsetInMinutes * 60000;
        }
        return this.timeZoneOffset;
    }

    /**
     * Форматирует дату следующим образом:
     *   Действие сегодня:       HH:mm
     *   Вчера:                  Вчера HH:mm
     *   В этом году:            dd название месяца
     *   В прошлом году:         dd название месяца yyyy
     * Название месяца - три буквы (янв, фев, мар, апр, мая, июн, июл, авг, сен, окт, ноя, дек).
     */
    public static formatDateTimeABC = (serverTimeMs: number): string => {
        const currentClientTimeMs = this.getCurrentClientTimeMs();
        const clientTimeMs = this.convertServerToClientTimeMs(serverTimeMs);
        const yesterdayTimeMs = this.getClientYesterdayStartMs();

        const roundedCurrentClientTimeMs = this.roundClientDateByTimeMs(currentClientTimeMs);
        const roundedClientTimeMs = this.roundClientDateByTimeMs(clientTimeMs);
        const roundedYesterdayTimeMs = this.roundClientDateByTimeMs(yesterdayTimeMs);

        /** Сегодня */
        if (roundedCurrentClientTimeMs === roundedClientTimeMs) {
            return this.formatDateToHHMM(clientTimeMs);
        }
        /** Вчера */
        else if (roundedYesterdayTimeMs === roundedClientTimeMs) {
            return `${pts.getLMsg("format2_yesterday")} ${this.formatDateToHHMM(clientTimeMs)}`
        }
        else {
            return this.createDefaultYearFormattingABC(currentClientTimeMs, clientTimeMs)
        }
    }

    /**
     * Форматирует дату следующим образом:
     *   Действие сегодня:       Сегодня
     *   Вчера:                  Вчера
     *   В этом году:            dd название месяца
     *   В прошлом году:         dd название месяца yyyy
     * Название месяца - три буквы (янв, фев, мар, апр, мая, июн, июл, авг, сен, окт, ноя, дек).
     */
    public static formatDateABC = (serverTimeMs: number): string => {
        const currentClientTimeMs = this.getCurrentClientTimeMs();
        const clientTimeMs = this.convertServerToClientTimeMs(serverTimeMs);
        const yesterdayTimeMs = this.getClientYesterdayStartMs();

        const roundedCurrentClientTimeMs = this.roundClientDateByTimeMs(currentClientTimeMs);
        const roundedClientTimeMs = this.roundClientDateByTimeMs(clientTimeMs);
        const roundedYesterdayTimeMs = this.roundClientDateByTimeMs(yesterdayTimeMs);

        /** Сегодня */
        if (roundedCurrentClientTimeMs === roundedClientTimeMs) {
            return pts.getLMsg("format_today");
        }
        /** Вчера */
        else if (roundedYesterdayTimeMs === roundedClientTimeMs) {
            return pts.getLMsg("format_yesterday");
        }
        else {
            return this.createDefaultYearFormattingABC(currentClientTimeMs, clientTimeMs)
        }
    }

    private static createDefaultYearFormattingABC = (currentClientTimeMs: number, clientTimeMs1: number) => {
        const currentClientDate = new Date(currentClientTimeMs);
        const clientDate = new Date(clientTimeMs1);

        const currentClientYear = currentClientDate.getFullYear();
        const clientYear = clientDate.getFullYear();
        const clientMonth = clientDate.getMonth() + 1;
        const clientDay = clientDate.getDate();

        const monthKey = `format2_month_${clientMonth}`;
        /** В этом году - dd название месяца */
        if (currentClientYear === clientYear) {
            return `${clientDay} ${pts.getLMsg(monthKey)}`;
        }
        /** В прошлом году - dd название месяца yyyy */
        return `${clientDay} ${pts.getLMsg(monthKey)} ${clientYear}`;
    }

    /**
     * Вычисляет разницу между началом дня каждой даты в милисекундах
     */
    public static getHNormDatesDifferMs = (dateMs1: number, dateMs2: number): number => {
        const startDate = new Date(dateMs1);
        const endDate = new Date(dateMs2);

        startDate.setHours(0,0,0,0);
        endDate.setHours(0,0,0,0);

        return Math.abs(startDate.getTime() - endDate.getTime());
    }
}